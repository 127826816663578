<template>
  <div class="app-container">
    <!-- 头部名称 交卷按钮 -->
    <div>
      
    </div>
<!-- main -->
    <div>
      <!-- 左边题型 -->
      <div class="table_height" :style="{height:contentStyleObj}" style="width:30%;border: 1px solid #efefef;padding: 10px;float: left;">
        <div style="border: 1px solid #efefef;padding: 10px;margin-bottom: 10px;"  class="radioDati" v-if="this.danxuanti.length != 0">
          <p>单选题</p>
          <el-radio-group v-model="danxuantiValue" >
            <el-radio-button style="margin:2px;border-left: 1px solid #dcdfe6;" border v-for="(item,index) in danxuanti" :key="index" :label="item.id" @change="changeTimuType(item)">{{ item.index }}</el-radio-button>
          </el-radio-group>
        </div>
        <div style="border: 1px solid #efefef;padding: 10px;margin-bottom: 10px;" class="radioDati" v-if="this.duoxuanti.length != 0">
          <p>多选题</p>
          <el-radio-group v-model="danxuantiValue">
            <el-radio-button style="margin:2px;border-left: 1px solid #dcdfe6;" border v-for="(item,index) in duoxuanti" :key="index" :label="item.id" @change="changeTimuType(item)"> {{ item.index }}</el-radio-button>
          </el-radio-group>
        </div>
        <div style="border: 1px solid #efefef;padding: 10px;margin-bottom: 10px;" class="radioDati" v-if="this.panduanti.length != 0">
          <p>判断题</p>
          <el-radio-group v-model="danxuantiValue">
            <el-radio-button style="margin:2px;border-left: 1px solid #dcdfe6;" border v-for="(item,index) in panduanti" :key="index" :label="item.id" @change="changeTimuType(item)">{{ item.index }}</el-radio-button>
          </el-radio-group>
        </div>
        <div style="border: 1px solid #efefef;padding: 10px;" class="radioDati" v-if="this.bdxXuanzeti.length != 0">
          <p>不定项选择题</p>
          <el-radio-group v-model="danxuantiValue">
            <el-radio-button style="margin:2px;border-left: 1px solid #dcdfe6;" border v-for="(item,index) in bdxXuanzeti" :key="index" :label="item.id" @change="changeTimuType(item)">{{ item.index }}</el-radio-button>
          </el-radio-group>
        </div>
      </div>
      <!-- 题目内容框架 -->
      <div class="table_height" :style="{height:contentStyleObj}" style="width:70%;border: 1px solid #efefef;padding: 10px;">
        
        <!-- 单选题的 -->
        <div v-if="this.listTimu.type == 1">
          <p style="border-bottom: 1px solid #3c6ff2;margin-bottom: 5px;padding-bottom: 5px;">一、单项选择题 (本类题共9小题，每小题2分，共18分。每小题备选答案中，只有一个符合题意的正确答案。错选、不选均不得分。请使用计算机鼠标在计算机答题界面上点击试题答案备选项前的按钮“○”作答。)</p>
          <p style="margin-bottom: 10px;font-weight: 600;">第{{ this.listTimu.key }}题</p>
          <P style="margin-bottom: 10px;">{{ this.listTimu.title }}</P>
          <el-radio style="display: block;" v-model="this.listTimu.danxuantiAnswer" label="A">A、{{ this.listTimu.key1 }}</el-radio>
          <el-radio style="display: block;" v-model="this.listTimu.danxuantiAnswer" label="B">B、{{ this.listTimu.key2 }}</el-radio>
          <el-radio style="display: block;" v-model="this.listTimu.danxuantiAnswer" label="C">C、{{ this.listTimu.key3 }}</el-radio>
          <el-radio style="display: block;" v-model="this.listTimu.danxuantiAnswer" label="D">D、{{ this.listTimu.key4 }}</el-radio>
        </div>
        <!-- 多选题的 -->
        <div v-else-if="this.listTimu.type == 2">
          <p style="border-bottom: 1px solid #3c6ff2;margin-bottom: 5px;padding-bottom: 5px;">二、多项选择题 (本类题共13小题，每小题2分，共26分。请至少选择两个答案，全部选对得满分，少选得相应分值，多选、错选、不选均不得分。请使用计算机鼠标在计算机答题界面上点击试题答案备选项前的按钮“□”作答。)</p>
          <p style="margin-bottom: 10px;font-weight: 600;">第{{ this.listTimu.key }}题</p>
          <P style="margin-bottom: 10px;">{{ this.listTimu.title }}</P>
          <el-checkbox-group v-model="this.listTimu.checkList" @change="changeDuoxuan">
            <el-checkbox style="display: block;" label="A">A、{{ this.listTimu.key1 }}</el-checkbox>
            <el-checkbox style="display: block;" label="B">B、{{ this.listTimu.key2 }}</el-checkbox>
            <el-checkbox style="display: block;" label="C">C、{{ this.listTimu.key3 }}</el-checkbox>
            <el-checkbox style="display: block;" label="D">D、{{ this.listTimu.key4 }}</el-checkbox>
            <el-checkbox style="display: block;" v-if="this.listTimu.key5 !=''&& this.listTimu.key5 != '0' " label="E">E、{{ this.listTimu.key5 }}</el-checkbox>
            <el-checkbox style="display: block;" v-if="this.listTimu.key5 !=''&& this.listTimu.key5 != '0'" label="F">F、{{ this.listTimu.key6 }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <!-- 判断题的 -->
        <div v-else-if="this.listTimu.type == 3">
          <p style="border-bottom: 1px solid #3c6ff2;margin-bottom: 5px;padding-bottom: 5px;">三、判断题 (本类题共5小题，每小题1分，共5 分。请判断每小题的表述是否正确。每小题答题正确的得1分，错答、不答均不得分，也不扣分。请使用计算机鼠标在计算机界面上点击试题答案备选项前的按钮“○”作答。)</p>
          <p style="margin-bottom: 10px;font-weight: 600;">第{{ this.listTimu.key }}题</p>
          <P style="margin-bottom: 10px;">{{ this.listTimu.title }}</P>
          <el-radio style="display: block;" v-model="this.listTimu.panduantiAnswer" label="A">A、正确</el-radio>
          <el-radio style="display: block;" v-model="this.listTimu.panduantiAnswer" label="B">B、错误</el-radio>
        </div>

        <!-- 不定项选择题 -->
        <div v-else-if="this.listTimu.type == 5">
          <p style="border-bottom: 1px solid #3c6ff2;padding-bottom: 5px;">四、不定项选择题 (本类题共3小题，12小问，每小问2分，共24分。每小题备选答案中，有一个或一个以上符合题意的正确答案。每小题全部选对得满分，少选得相应分值，多选、错选、不选均不得分。请使用计算机鼠标在计算机答题界面上点击试题答案备选项前的按钮“□”作答。)</p>
          <div style="float:left;padding:1%;width: 50%;border-right: 3px solid #efefef;">
            <div style="margin-bottom: 10px;font-weight: 600;">第{{ this.listTimu.key }}题</div>
            <div style="margin-bottom: 10px">{{ this.listTimu.title }}</div>
          </div>
          <!-- <el-radio style="display: block;" v-model="this.listTimu.panduantiAnswer" label="A">A、正确</el-radio>
          <el-radio style="display: block;" v-model="this.listTimu.panduantiAnswer" label="B">B、错误</el-radio> -->
          <div style="width: 48%;padding: 1%;float: left;">
            
          </div>
        </div>

        <div style="position: absolute;bottom: 23px;padding: 1%;">
          <el-button type="primary" @click="lastOne">上一题</el-button>
          <el-button type="primary" @click="nextOne">下一题</el-button>
          <el-popover
            placement="top"
            :title="this.listTimu.answer == 0?'错误':this.listTimu.answer == 1?'正确':this.listTimu.answer"
            :width="200"
            trigger="click"
            :content="this.listTimu.intro"
          >
            <template #reference>
              <el-button type="success">查看答案及解析</el-button>
            </template>
          </el-popover>
        </div>
      </div>
    </div>
    <!-- 获取全部带选择答案的list -->
    <!-- <el-button @click="changeLIst">222</el-button> -->
    
  </div>
</template>

<script>
import { questionBankList } from "@/api/example"

export default {
  name: 'startExam',

  data() {
    return {
      contentStyleObj:{}, //高度变化

      danxuantiValue:'1',
      danxuanti:[],
      duoxuanti:[],
      panduanti:[],
      bdxXuanzeti:[],
      listTimu:{},
      checkList:[],
      danxuantiAnswer:'',
      panduantiAnswer:'',
      listAnswwer:[],
    };
  },
  created() {
    this.contentStyleObj=this.$getHeight(380)
    // this.init()
    this.getList()
  },

  mounted() {
    
  },

  methods: {
    init(){
     this.listTimu = this.list
     this.listTimu.key = 1
    },
    // 调接口
    getList(){
      let param
      let id = this.$store.getters["commons/params"].exampleDetailId
      questionBankList({treeId:id}).then(res => {
        this.listTimu = res.data.data.list
        this.danxuanti = this.listTimu.filter(v=>{return v.type == 1})
        this.danxuanti.map(v => {
          v.index = (this.danxuanti.indexOf(v)+1)
        })
        this.duoxuanti = this.listTimu.filter(v=>{return v.type == 2})
        this.duoxuanti.map(v => {
          v.index = (this.duoxuanti.indexOf(v)+1)
        })
        this.panduanti = this.listTimu.filter(v=>{return v.type == 3})
        this.panduanti.map(v => {
          v.index = (this.panduanti.indexOf(v)+1)
        })
        this.bdxXuanzeti = this.listTimu.filter(v=>{return v.type == 5})
        this.bdxXuanzeti.map(v => {
          v.index = (this.bdxXuanzeti.indexOf(v)+1)
        })
        this.listTimu = this.danxuanti[0]
        this.listTimu.key = this.danxuanti[0].index
        this.danxuantiValue = this.danxuanti[0].id
      })
    },
    changeDuoxuan(){
      console.log(this.checkList);
    },
    // 切换的是单选题还是多选题
    changeTimuType(item){
      console.log(item);
      this.listTimu = item
      this.listTimu.key = item.index
    },
    changeLIst(){
      // 获取选完答案的
      this.listAnswwer = this.danxuanti.concat(this.duoxuanti).concat(this.panduanti)
    },
    // 切换上一题
    lastOne(){
      if(this.listTimu.type == 1){
        if(this.listTimu.index == 1){
          this.$qzfMessage("已经是第一题了", 1)
          return
        }
        this.changeTimuType(this.danxuanti[this.listTimu.index-2])
        this.danxuantiValue = this.danxuanti[this.listTimu.index-1].id
      }else if(this.listTimu.type == 2){
        if(this.listTimu.index == 1){
          // 切换到单选题
          this.changeTimuType(this.danxuanti[this.danxuanti.length-1])
          this.danxuantiValue = this.danxuanti[this.danxuanti.length-1].id
          return
        }
        this.changeTimuType(this.duoxuanti[this.listTimu.index-2])
        this.danxuantiValue = this.duoxuanti[this.listTimu.index-1].id
      }else if(this.listTimu.type == 3){
        if(this.listTimu.index == 1){
          // 切换到多选题
          this.changeTimuType(this.duoxuanti[this.duoxuanti.length-1])
          this.danxuantiValue = this.duoxuanti[this.duoxuanti.length-1].id
          return
        }
        this.changeTimuType(this.panduanti[this.listTimu.index-2])
        this.danxuantiValue = this.panduanti[this.listTimu.index-1].id
      }
    },
    // 切换下一题
    nextOne(){
      if(this.listTimu.type == 1){
        if(this.listTimu.index == this.danxuanti.length){
          // 切换到多选了
          this.changeTimuType(this.duoxuanti[0])
          this.danxuantiValue = this.duoxuanti[0].id
          return
        }
        this.changeTimuType(this.danxuanti[this.listTimu.index])
        this.danxuantiValue = this.danxuanti[this.listTimu.index-1].id
      }else if(this.listTimu.type == 2){
        if(this.listTimu.index == this.duoxuanti.length){
          // 切换到p判断题了
          this.changeTimuType(this.panduanti[0])
          this.danxuantiValue = this.panduanti[0].id
          return
        }
        this.changeTimuType(this.duoxuanti[this.listTimu.index])
        this.danxuantiValue = this.duoxuanti[this.listTimu.index-1].id
      }else if(this.listTimu.type == 3){
        if(this.listTimu.index == this.panduanti.length){
          this.$qzfMessage("已经是最后一题了", 1)
          return
        }
        this.changeTimuType(this.panduanti[this.listTimu.index])
        this.danxuantiValue = this.panduanti[this.listTimu.index-1].id
      }
    }
    
  },
};
</script>

<style lang="scss" scoped>
.table_height{
  overflow-y: overlay;
  overflow-x:hidden;
}

</style>
<style lang="scss">
.radioDati{
  .el-radio-button:first-child .el-radio-button__inner{
    border-left: none !important;
  }
}
</style>